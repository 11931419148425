@font-face {
    font-family: montserrat-regular;
    src: url("/public/fonts/Montserrat-Regular.ttf");
  }
  @font-face {
    font-family: montserrat-medium;
    src: url("/public/fonts/Montserrat-Medium.ttf");
  }
  @font-face {
    font-family: montserrat-semiBold;
    src: url("/public/fonts/Montserrat-SemiBold.ttf");
  }
  @font-face {
    font-family: montserrat-light;
    src: url("/public/fonts/Montserrat-Light.ttf");
  }
  @font-face {
    font-family: kanit-bold;
    src: url("/public/fonts/Kanit-Bold.ttf");
  }

.Navbar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  width: 100% !important;
  padding-left: 25px;
  padding-right: 30px;
  z-index: 11;
}
.nav-items{
display: flex;
align-items: center;
}
*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.nav_items{
padding-right: 20px;
font-size: 16px;
font-family: montserrat-regular;
color: #828282;
}

.nav_items:hover {
   color: #2F80ED;
    transition: all 0.55s;
    font-family: montserrat-semiBold;
  cursor: pointer;
}
.login_btn{
background-color: #2F80ED;
color: #fff;
font-size: 15px;
width: 137px;
border-radius: 10px;
border: none;
height: 40px;
font-family: montserrat-semiBold;
}
.login_btn:hover {
  border: 2px solid #2F80ED;
  animation: slidebg 2s linear infinite;
  background: none;
  color: #2F80ED;
  transition: all 0.45s;
}
.Navbar > .nav-logo {
  font-weight: 700;
  font-size: 21px;
  margin: 15px;
  color: #1d7e49;
}

.Navbar > .nav-items > a {
  color: #ffffff;
  font: 12px popreg !important;
  text-decoration: none;
  margin: 20px;
  position: relative;
  opacity: 0.9;
  /* padding-bottom: 10px; */
}

/* .Navbar > .nav-items > :hover {
  opacity: 1;
} */

/* .Navbar > .nav-items > ::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #8f00ff !important;
  transition: all 0.45s;
} */

.Navbar > .nav-items > :hover::before {
  width: 100%;
}

.bttn:hover::before {
  width: 0% !important;
}

.Navbar > .nav-toggle {
  display: none;
}


@media (max-width: 768px) {
  .Navbar > .nav-items {
    position: absolute;
    top: 78px;
    display: flex;
    flex-direction: column;
    left: 0;
    padding-top: 23px;
    width: 100%;
    height: 334px;
    transform: translateX(-100%);
    transition: all 0.45s;
  }

  .Navbar > .nav-items > a::before {
    background: transparent;
  }

  .Navbar > .nav-items.open {
    transform: translateX(0);
    background-color: #dee2e6;
  }
  .Navbar > .nav-items > {
    color: #2F80ED;
  }

  .Navbar > .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  

  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #2F80ED;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #2F80ED;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }
  .pro_img {
margin-top: 10px;
  }
  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
  .nav_items {
padding-bottom: 15px;
  }

}
