* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
h2{
  text-align: center;
  color:#333333;
  font-family: kanit-bold;
 font-size: 32px;
}
.hero_flex{
display: flex;
padding-left: 50px;
padding-right: 50px;
justify-content: space-between;
}
.main_text{
margin-top: 60px;
padding: 10px 20px;
margin-bottom: 30px;
}
.text_div{
  display: flex;
  
}
.hero_img img {
  height: 450px;
  padding-top: 60px;
}
.section2{
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.card1{
  box-shadow: 0px 0.12962962687015533px 1.4166666269302368px 0px #00000004;

  box-shadow: 0px 0.5703703761100769px 2.933333396911621px 0px #00000007;
  
  box-shadow: 0px 1.399999976158142px 5.849999904632568px 0px #00000009;
  
  box-shadow: 0px 2.696296215057373px 11.466666221618652px 0px #0000000B;
  
  box-shadow: 0px 4.537036895751953px 21.08333396911621px 0px #0000000E;
  
  box-shadow: 0px 7px 36px 0px #00000012;
  
}
.cards{
display: flex;
justify-content: space-evenly;
border: none;
padding-top: 60px;
border-radius: 9px;

}
.card{
  width: 350px;
  height: 432px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #d0cece;
  border-radius: 7px;
  margin-bottom: 60px;
  text-align: center;
}
.card:hover {
  transition: all 0.55s;
  background-color: #a3c6f4;
  cursor: pointer;
}
.card img {
  width: 150px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}
.text_1{
  color: #333333;
  font-family: kanit-bold;
  padding-bottom: 20px;
}
.text_2{
  font-size: 13px;
  font-family: montserrat-regular;
  color:#828282;
}
.s_div{
  box-shadow: 0px 0.12962962687015533px 1.4166666269302368px 0px #00000004;

  box-shadow: 0px 0.5703703761100769px 2.933333396911621px 0px #00000007;
  
  box-shadow: 0px 1.399999976158142px 5.849999904632568px 0px #00000009;
  
  box-shadow: 0px 2.696296215057373px 11.466666221618652px 0px #0000000B;
  
  box-shadow: 0px 4.537036895751953px 21.08333396911621px 0px #0000000E;
  
  box-shadow: 0px 7px 36px 0px #00000012;
  width: 142px;
  height: 142px;
}
.s_div:hover{
  background-color: #a3c6f4;
  border-radius: 7px;
  cursor: pointer;
}
.s_div img {
  margin-left: 50px;
  padding-top: 20px;
  width: 40px;
  padding-bottom: 30px;
  height: 100px;
}
.part_2{
display: flex;
justify-content: space-around;
}
.tex_s{
text-align: center;
font-size: 13px;
color: #333333;
font-weight: 600;
font-family: montserrat-regular;
}
.text_6{
  font-family: montserrat-regular;
}
.blu{
  background-color: #2F80ED;
  color: #fff;
  font-family: montserrat-semiBold;
  border-radius: 7px;
  text-align: center;
  padding-top: 40px;
}
.section3{
  padding-top: 60px;
}
.seccards{
  display: flex;
  justify-content: space-evenly;
  padding-top: 40px;
  padding-bottom: 60px;
}
.card4{
  box-shadow: 0px 0.12962962687015533px 1.4166666269302368px 0px #00000004;

  box-shadow: 0px 0.5703703761100769px 2.933333396911621px 0px #00000007;
  
  box-shadow: 0px 1.399999976158142px 5.849999904632568px 0px #00000009;
  
  box-shadow: 0px 2.696296215057373px 11.466666221618652px 0px #0000000B;
  
  box-shadow: 0px 4.537036895751953px 21.08333396911621px 0px #0000000E;
  
  box-shadow: 0px 7px 36px 0px #00000012;
  
  background-color: #fff;
  width: 251px;
  border-radius: 7px;
  text-align: center;
  height: 395px;
  padding-top: 40px;
}
.heading_card{
  color: #2F80ED;
  font-size: 26px;
}
.review{
  text-align: center;
height: 390px;
padding-bottom: 30px;
width: 36%;
box-shadow: 0px 0.12962962687015533px 1.4166666269302368px 0px #00000004;

box-shadow: 0px 0.5703703761100769px 2.933333396911621px 0px #00000007;

box-shadow: 0px 1.399999976158142px 5.849999904632568px 0px #00000009;

box-shadow: 0px 2.696296215057373px 11.466666221618652px 0px #0000000B;

box-shadow: 0px 4.537036895751953px 21.08333396911621px 0px #0000000E;

box-shadow: 0px 7px 36px 0px #00000012;
border-top: 4px solid #ffc108;
padding-top: 50px;
padding-left: 30px;
padding-right: 30px;
}
.review_flex{
  display: flex;
  padding-top: 50px;
  justify-content: space-evenly;
  padding-left: 50px;
  padding-right: 50px;
}
.sections4{
  padding-bottom: 60px;
}
.contact_us{
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
padding-top: 40px;
border-radius: 7px;
padding-bottom: 40px;
  margin-left: auto;
  height: 410px;
  margin-right: auto;
  width: 60%;
  box-shadow: 0px 0.12962962687015533px 1.4166666269302368px 0px #00000004;

box-shadow: 0px 0.5703703761100769px 2.933333396911621px 0px #00000007;

box-shadow: 0px 1.399999976158142px 5.849999904632568px 0px #00000009;

box-shadow: 0px 2.696296215057373px 11.466666221618652px 0px #0000000B;

box-shadow: 0px 4.537036895751953px 21.08333396911621px 0px #0000000E;

box-shadow: 0px 7px 36px 0px #00000012;

}
.img_section{
  width: 50%;

}
.contact_section{
width: 50%;
}
.field{
  outline: none;
  border-bottom: 0.8px solid #828282;
  border-top: none;
  border-right: none;
  border-left: none;
  width: 80%;
  font-family: montserrat-regular;
  font-size: 14px;
  padding-bottom: 10px;
}
.footer{
background-color: #2F80ED;
}
.logo{
text-align: center;
padding-top: 30px;
padding-bottom: 30px;
}
.footer_content{
  display: flex;
  justify-content: space-evenly;
}
.h1{
  font-family: kanit-bold;
  font-size: 16px;
  color: #C5EAFF;
}
.h5{
  color: #fff;
  font-size: 14px;
  font-family: montserrat-regular;
}
.login_title{
  font-size: 24px;
  font-family: kanit-bold;
}
.border_line{
margin-left: auto;
margin-right:auto;
width: 70%;
height: 0.7px;
background-color:#C5EAFF;
}
.login_flex{
  display: flex;
  height: 100vh;
  text-align: center;
}
.flex__1{
  width: 50%;
  background-color: #2F80ED;
}
.flex__2{
  width: 50%;
 
}
.signup_fix{
  padding-bottom: 45px;
}
.flex__1 img {
  height: 90px;
  position: absolute;
  top: 50%;
  left: 23%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
.content_login{
padding-top: 80px;
}
.login_fix{
padding-top: 70px;
padding-bottom: 120px;
}
.span{
color: #2F80ED;
font-family: kanit-bold;
font-size: 17px;
}
.content_login img{
display: none;
}
.edit_section{
text-align: center;
padding-top: 60px;
}
.fix_edit{
  height: 120px;
}
.update_btn{
color: #2F80ED;
background-color: #cae0fc;
width: 220px;
height: 40px;
margin-left: auto;
margin-top: 25px;
margin-right: auto;
border-radius: 7px;
font-family: montserrat-regular;
padding-top: 8px;
font-weight: 700;
font-size: 15px;
}
.h_h{
  margin-left: auto;
  margin-right: auto;
  width: 65%;
}
.success_title{
  font-size: 12px;
  margin-top: 40px;
  color: #333333;
  text-align: center;
  font-family: montserrat-regular;
}
.slot_div{
   padding-right: 40px;
   padding-top: 40px;
}
.form-control {
  color: #828282;
  font-size: 15px;
}
.success_title2{
font-size: 11px;
font-family: kanit-bold;
color: #828282;
padding-top: 15px;
text-align: center;
}
.field2{
display: block;
margin-left: auto;
margin-right: auto;
width: 45%;
}
.textarea{
width: 65%;
text-align: center;
margin-left: auto;
margin-right: auto;
}
.backbtn{
  font-family: kanit-bold;
  color: #EB5757;
  padding-left: 5px;
}
.bottom_line{
  background-color:#F2F2F2;
  height: 300px;
  width: 4px;
}

.header_flex{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.items_cnt{
align-items: center;
padding-left: 40px;
}
.slot{
padding-top: 40px;
}
.solt_flex{
  display: flex;
  margin-top: 60px;
justify-content: center;

}
.slect_appoint{
padding-left: 60px;
}
.calender{
  padding-right: 60px;
}
.slot_number{
  border:none;
  background-color: #2F80ED;
  font-family: kanit-bold;
  height: 40px;
  width: 160px;
  border-radius: 7px;
  font-size: 14px;
  color: #fff;
    }
    .gray{
      background-color: #E0E0E0;
    }
/* Mobile---View */
@media (max-width: 586px) {
  .hero_flex{
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
  }
  .flex__1{
   display: none;
  }

  .cards{
flex-direction: column;
  }
  .part_2{
    flex-direction: column;
  }
  .card{
    width: auto;
    height: 350px;
  }
  .card img{
    height: 120px;
  }
  .text_2 {
font-size: 11px;
}
.s_div{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.field2{
width: 70%;
}
.update_btn{
  margin-bottom: 85px;
}
.content_login img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  }
.card4{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.seccards{
  flex-direction: column;
}
.navbar img {
  height: 33px;
}
.review_flex{
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
}
.review{
  width: 100%;
  margin-bottom: 20px;
}
.text_6{
font-size: 10px;
}
.gpp{
font-size: 12px;
padding-left: 30px;
padding-right: 30px;
}
h2{
  font-size: 24px;
}
.mbl_c{
font-size: 14px;
}
.contact_us{
  flex-direction: column;
  width: 80%;
  height: 580px;
}
.img_section img {
  height: 130px;
  margin-bottom: 40px;
}
.contact_section {
width: 95%;
}
.footer_content{
flex-direction: column;
text-align: center;
}
.content1{
margin-bottom: 20px;
}
.flex__2{
  width: 100%;
}
.content_login{
  padding-top: 70px;
}
.login_title {
padding-top: 80px;
}
}